var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.myTitle)+" ")])],1)],2),_c('Heading',{attrs:{"heading":"h4","content":_vm.myTitle}}),_c('hr'),_c('el-form',{ref:"form",attrs:{"label-width":"80px"}},[(_vm.isAdmin)?_c('el-form-item',{attrs:{"label":"User"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","remote":"","reserve-keyword":"","placeholder":"Email / User","remote-method":_vm.getStudents,"loading":_vm.loading},on:{"change":_vm.getAllComposePractices},model:{value:(_vm.uid),callback:function ($$v) {_vm.uid=$$v},expression:"uid"}},_vm._l((_vm.students),function(student){return _c('el-option',{key:student.id,attrs:{"label":`${student.first_name} ${student.last_name} - [${student.email}]`,"value":student.id}})}),1)],1):_vm._e(),(_vm.uid)?_c('el-form-item',{attrs:{"label":"Test"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":""},on:{"change":_vm.getTests},model:{value:(_vm.tpo),callback:function ($$v) {_vm.tpo=$$v},expression:"tpo"}},_vm._l((_vm.practices),function(practice,index){return _c('el-option',{key:index,attrs:{"value":practice.id,"label":`${practice.exam.title}`}})}),1)],1):_vm._e()],1),(_vm.tableData)?[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"Student"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(`${scope.row.user.first_name} ${scope.row.user.last_name}`)+" ")]}}],null,false,3479692845)}),_vm._l((_vm.practicesSection),function(practice,index){return _c('el-table-column',{key:practice.id,attrs:{"label":_vm.titleCase(practice.subject.name)},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.exam.test_type ===
                'Modules\\Practice\\Entities\\ComposePractice'
            )?[_c('el-radio',{attrs:{"label":scope.row.id},model:{value:(_vm.radios[index].id),callback:function ($$v) {_vm.$set(_vm.radios[index], "id", $$v)},expression:"radios[index].id"}},[_vm._v("   ")])]:_vm._e(),(
              scope.row.exam.test_type ===
                'Modules\\Practice\\Entities\\Practice'
            )?[(scope.row.exam.test_id === practice.id)?[_c('el-radio',{attrs:{"label":scope.row.id},model:{value:(_vm.radios[index].id),callback:function ($$v) {_vm.$set(_vm.radios[index], "id", $$v)},expression:"radios[index].id"}},[_vm._v("   ")])]:_vm._e()]:_vm._e()]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"Total"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'SATResult',
              query: {
                practiceType:
                  scope.row.exam.test_type ===
                  'Modules\\Practice\\Entities\\ComposePractice'
                    ? 'composePractices'
                    : scope.row.exam.test_type ===
                      'Modules\\Practice\\Entities\\Practice'
                    ? 'singlePractice'
                    : ''
              },
              params: { id: scope.row.id }
            }}},[_c('b',[_vm._v(_vm._s(scope.row.total_score))])])]}}],null,false,3493584409)}),_c('el-table-column',{attrs:{"prop":"created_at","label":"Date","width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.moment(scope.row.created_at).format("YYYY-MM-DD hh:mm"))+" ")]}}],null,false,1221803760)})],2),_c('div',{staticClass:"text-center",staticStyle:{"margin":"20px 0"}},[_c('el-button',{attrs:{"type":"success","disabled":!_vm.canCombine},on:{"click":_vm.combineTests}},[_vm._v("Save")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }