<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <el-form ref="form" label-width="80px">
      <el-form-item label="User" v-if="isAdmin">
        <el-select
          v-model="uid"
          filterable
          remote
          reserve-keyword
          placeholder="Email / User"
          :remote-method="getStudents"
          :loading="loading"
          style="width: 100%"
          @change="getAllComposePractices"
        >
          <el-option
            v-for="student in students"
            :key="student.id"
            :label="
              `${student.first_name} ${student.last_name} - [${student.email}]`
            "
            :value="student.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Test" v-if="uid">
        <el-select
          v-model="tpo"
          filterable
          @change="getTests"
          style="width: 100%"
        >
          <el-option
            v-for="(practice, index) in practices"
            :key="index"
            :value="practice.id"
            :label="`${practice.exam.title}`"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template v-if="tableData">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column label="Student">
          <template slot-scope="scope">
            {{ `${scope.row.user.first_name}  ${scope.row.user.last_name}` }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="(practice, index) in practicesSection"
          :label="titleCase(practice.subject.name)"
          :key="practice.id"
        >
          <template slot-scope="scope">
            <template
              v-if="
                scope.row.exam.test_type ===
                  'Modules\\Practice\\Entities\\ComposePractice'
              "
            >
              <el-radio v-model="radios[index].id" :label="scope.row.id">
                &nbsp;
              </el-radio>
            </template>
            <template
              v-if="
                scope.row.exam.test_type ===
                  'Modules\\Practice\\Entities\\Practice'
              "
            >
              <template v-if="scope.row.exam.test_id === practice.id">
                <el-radio v-model="radios[index].id" :label="scope.row.id">
                  &nbsp;
                </el-radio>
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Total">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'SATResult',
                query: {
                  practiceType:
                    scope.row.exam.test_type ===
                    'Modules\\Practice\\Entities\\ComposePractice'
                      ? 'composePractices'
                      : scope.row.exam.test_type ===
                        'Modules\\Practice\\Entities\\Practice'
                      ? 'singlePractice'
                      : ''
                },
                params: { id: scope.row.id }
              }"
            >
              <b>{{ scope.row.total_score }}</b>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="Date" width="130px">
          <template slot-scope="scope">
            {{ moment(scope.row.created_at).format("YYYY-MM-DD hh:mm") }}
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center" style="margin:20px 0">
        <el-button type="success" @click="combineTests" :disabled="!canCombine"
          >Save</el-button
        >
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import SAT from "@/apis/sat";
import role from "@/mixins/role.js";

import Breadcrumb from "@/components/Breadcrumb";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [role],

  props: [],
  data() {
    return {
      uid: null,
      tpo: "",
      moment,
      students: [],
      tableData: null,
      practices: [],
      practicesSection: [],
      passageNum: {
        reading: 3,
        listening: 6,
        speaking: 6,
        writing: 2
      },
      radios: [],
      loading: false
    };
  },
  computed: {
    ...mapState("user", ["lang", "profile"]),
    myTitle() {
      return this.$t("sat.Combine Tests");
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    canCombine() {
      let canCombine = false;
      if (this.radios && this.radios.length > 0) {
        let sectionNumber = 0;
        this.radios.forEach(practice => {
          if (practice.id > 0) {
            sectionNumber++;
          }
        });
        if (sectionNumber === this.radios.length) {
          canCombine = true;
        }
      }
      return canCombine;
    }
  },
  watch: {
    isAdmin() {
      if (!this.isAdmin) {
        this.uid = this.profile.id;
        this.getAllComposePractices();
      }
    },
    profile() {
      if (!this.isAdmin) {
        this.uid = this.profile.id;
        this.getAllComposePractices();
      }
    }
  },

  mounted() {
    if (!this.isAdmin) {
      this.uid = this.profile.id;
      this.getAllComposePractices();
    }
  },

  methods: {
    titleCase(str) {
      let tmp = str.toLowerCase();
      tmp = tmp.substring(0, 1).toUpperCase() + tmp.substring(1);
      return tmp;
    },
    async getAllComposePractices() {
      this.practices = [];
      this.tpo = "";
      this.tableData = null;
      if (this.uid) {
        const res = await SAT.getAllComposePractices({
          user_id: this.uid
        });
        this.practices = res.compose_practice;
      }
    },
    async getStudents(query) {
      this.loading = true;
      if (query !== "") {
        const students = await SAT.getStudents({ keyword: query });
        this.loading = false;
        this.students = students.users;
      } else {
        this.students = [];
      }
    },
    async getTests() {
      if (this.uid && this.tpo) {
        const res = await SAT.getTests({
          user_id: this.uid,
          compose_practice_id: this.tpo
        });
        this.tableData = res.user_exams;
        const practices = await SAT.getComposePracticePractices(this.tpo);
        this.practicesSection = practices.compose_practice.practices;
        let radios = [];
        this.practicesSection.forEach(practice => {
          radios.push({
            practice_id: practice.id,
            id: ""
          });
        });
        this.radios = radios;
      }
    },
    async combineTests() {
      if (this.canCombine) {
        await SAT.combineTests(this.tpo, {
          user_id: this.uid,
          user_exams: this.radios
        });
        this.$message({
          message: "Success!",
          type: "success!"
        });
        this.getTests();
      } else {
        this.$message({
          message: "Please select complete and non-duplicate items to merge!",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-table td.el-table__cell div {
  text-align: center;
}
</style>
